<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      Edit Stat Groups
    </template>

    <template #cardBody>
      <v-container class="pa-6">
        <v-row>
          <v-col
            cols="12"
            :md="6"
          >
            <v-select
              v-model="selectedGlobalSportId"
              :items="sports"
              item-text="name"
              item-value="globalSportId"
              @change="getSportSpecificStatGroups"
              label="Sport"
              solo-inverted
            />
          </v-col>
          <v-col
            cols="12"
            :md="6"
          >
            <v-select
              v-model="selectedStatGroupId"
              :items="sportSpecificStatGroups"
              item-text="name"
              item-value="id"
              :disabled="selectedGlobalSportId == null"
              label="Stat Group"
              solo-inverted
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col
            v-for="(stat, statIndex) in selectedStatsSorted"
            :key="statIndex"
            cols="12"
            :sm="6"
            :md="4"
            :lg="3"
          >
            <v-checkbox
              v-model="stat.enabled"
              @change="toggleStatMask(stat)"
              :label="stat.name"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

export default {
  name: "StatGroups",

  components: {
    ViewCardTemplate,
  },

  props: {
    //
  },

  data: () => ({
    loaded: false,
    
    sports: [],
    selectedGlobalSportId: null,

    maskedStatIdSet: [],

    sportSpecificStatGroups: [],
    selectedStatGroupId: null,
  }),

  computed: {
    selectedStatsSorted() {
      if(this.selectedStatGroupId) {
        return this.sportSpecificStatGroups
          .find(sg => sg.id == this.selectedStatGroupId)
          .stats
          .sort((a, b) => a.name.localeCompare(b.name))
      }
      
      return []
    },
  },

  methods: {
    async toggleStatMask(stat) {
      // TODO: Error handling
      if(stat.enabled) {
        await Controllers.StatMaskController.AdminDeleteStatMask(stat.id)
        this.$root.showSnackbar(`"${stat.name}" enabled`, 'success', 1500)
      }
      else {
        await Controllers.StatMaskController.AdminCreateStatMask(stat.id)
        this.$root.showSnackbar(`"${stat.name}" disabled`, 'error', 1500)
      }
    },

    async getSportSpecificStatGroups(globalSportId) {
      this.selectedStatGroupId = null

      const statGroupsRes = await Controllers.StatGroupController.AdminListStatGroups(globalSportId)
      const statGroups = statGroupsRes.data

      // Attach masks to stats.
      statGroups.forEach(sg => {
        sg.stats.forEach(stat => {
          stat.enabled = !this.maskedStatIdSet.has(stat.id)
        })
      })

      // Alphabetize the statgroups for easy select navigation.
      this.sportSpecificStatGroups = statGroups.sort((a, b) => a.name.localeCompare(b.name))
    },
  },

  async created() {
    this.$root.updateTitle("Edit Stat Groups")
    
    // Fetch & filter sports.
    let sports =  await this.$store.getters.conferenceSports
    sports = sports.filter(s => s.hasStats)
    this.sports = sports

    // Pre-fetch stat masks. (Since they aren't sport specific.)
    const statMasksRes = await Controllers.StatMaskController.ListStatMasks()
    this.maskedStatIdSet = new Set(statMasksRes.data.map(sm => sm.statId))

    this.loaded = true
  },
}
</script>

<style>

</style>